import Script from 'next/script';
import { getDomain } from './domains';

export default function Plausible({ environment }) {
  function plausibleScript() {
    if (environment === 'dev') {
      return 'https://plausible.io/js/script.hash.outbound-links.manual.pageview-props.local.js';
    } else {
      return 'https://plausible.io/js/script.hash.outbound-links.manual.pageview-props.js';
    }
  }

  function plausibleDomain() {
    if (environment === 'dev') {
      return 'dev.isso.dev';
    } else return getDomain(environment);
  }
  return (
    <Script
      defer
      data-domain={plausibleDomain()}
      src={plausibleScript()}
      strategy="beforeInteractive"
    />
  );
}
